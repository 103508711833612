<template>
  <!-- Breadcroumbs start -->
  <Breadcrumbs title="My Profile" />
  <!-- Breadcroumbs end -->
  <!-- Account Overview start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="row flex-lg-row-reverse">
        <div class="col-12 col-lg-9">
          <div class="my-account-page-title">
            <h3>My Account Overview</h3>
          </div>
          <div class="my-account-section">
            <h5>
              Personal Information <router-link to="/profile"><span>
              <i class="fa fa-edit"  aria-hidden="true">

                </i></span></router-link>
            </h5>
            <ul>
              <li><span>First Name:</span><span>{{name}}</span></li>
              <li><span>Company:</span><span>Web24service</span></li>
              <li><span>Mobile:</span><span>+88 01911837404</span></li>
              <li><span>Email:</span><span>{{email}}</span></li>
              <li><span>Country:</span><span>Bangladesh</span></li>
              <li>
                <span>Address:</span>
                <span
                  >It was popularised in the 1960s with the release of Letraset sheets containing.
                </span>
              </li>
            </ul>
          </div>
          <div class="my-account-section">
            <h5>
              Shipping Address <router-link to="/profile"><span>
              <i class="fa fa-edit"  aria-hidden="true">

                </i></span></router-link>
            </h5>
            <ul>
              <li><span>Name:</span><span>Razu Ahmed</span></li>
              <li>
                <span>Address:</span
                ><span>
                  It was popularised in the 1960s with the release of Letraset sheets
                  containing.</span
                >
              </li>
              <li><span>Mobile:</span><span>+88 01911837404</span></li>
            </ul>
          </div>
        </div>
<ProfileNavigation />
      </div>
    </div>
  </div>
  <!-- Account Overview End -->

  <Newsletter />
</template>

<script>
// @ is an alias to /src
import { auth, profilesCollection } from "@/includes/firebase";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ProfileNavigation from "@/components/ProfileNavigation.vue";
import Newsletter from "@/components/Newsletter.vue";

export default {
  name: "Admin",
  data() {
    return {
      name: null,
      email: null,
    };
  },
  components: {
    Breadcrumbs,
    Newsletter,
    ProfileNavigation,
  },
  methods: {
    signout() {
      auth.signOut()
        .then(() => {
          this.$router.replace("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateProfile() {
      profilesCollection.doc(this.uid).update(this.profile);
      console.log(this.profile);
    },
  },
  created() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        const { displayName } = user;
        const { email } = user;

        this.name = displayName;
        this.email = email;
      } else {
        console.log("No user is signed in");
      }
    });
  },
};
</script>
 <style scoped>
 .fa.fa-edit {
   color: #000;
 }
  .fa.fa-edit:hover {
   color: #ffcd00;
 }
 </style>
