<template>
  <div class="col-12 col-lg-3">
    <div class="left-block left-menu mt-md-30">
      <ul>
        <li>
          <router-link :to="{ name: 'admin' }" title="My Account"
            >My Account Overview</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'profile' }">Edit Account</router-link>
        </li>
        <li><router-link :to="{ name: 'orders' }">My Orders</router-link></li>
        <!-- <li><a href="my-wishlist.html" title="My Wishlist">My Wishlist</a></li> -->
        <li><router-link :to="{ name: 'mycart' }">My Cart</router-link></li>
        <li>
          <a href="order-tracking.html" changePassword>Order Tracking</a>
        </li>
        <!-- <li><a href="order-form.html" title="Order Form">Order Form</a></li> -->

        <li>
          <router-link :to="{ name: 'changepassword' }"
            >Change Password</router-link
          >
        </li>

        <li><router-link to="/" @click="signout()">Sign out</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileNavigation",
  data() {
    return {
      class: "",
    };
  },
  methods: {
    signout() {
      this.$store.dispatch("signout", {
        router: this.$router,
        route: this.$route,
      });

      // console.log(this.$route);
      if (this.$route.meta.requiresAuth) {
        this.$router.push({ name: "home" });
      }
    },
  },

  components: {},
};
</script>

<style scoped>
.router-link-exact-active {
  background-color: #ffcd00;
}
</style>
